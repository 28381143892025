import { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import MatchedDeals from './components/MatchedDeals';
import Keywords from './components/Keywords';
import KeywordForm from './components/KeywordForm';
import Header from './components/Header';
import { DealsProvider } from './context/DealsContext';
import DealsContext from './context/DealsContext';

function App() {
  const { allDeals, filteredDeals } = useContext(DealsContext);
  return (
    <Router>
      <Header />
      <div className="container">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <MatchedDeals deals={filteredDeals} filtered="1" />
              </>
            }
          ></Route>
          <Route
            path="/all_deals"
            element={
              <>
                <MatchedDeals deals={allDeals} />
              </>
            }
          ></Route>
          <Route
            path="/keywords"
            element={
              <>
                <Link to="/">Go to Matched Deals</Link>
                <KeywordForm />
                <Keywords />
              </>
            }
          ></Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
