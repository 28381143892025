import { useState, useContext, useEffect } from 'react';
import Card from './shared/Card';
import Button from './shared/Button';
import DealsContext from '../context/DealsContext';

function KeywordForm() {
  const [text, setText] = useState('');
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [message, setMessage] = useState('');

  const { addKeyword, editingKeyword, updateKeyword } =
    useContext(DealsContext);

  useEffect(() => {
    if (editingKeyword.edit === true) {
      setBtnDisabled(false);
      console.log(editingKeyword);
      setText(editingKeyword.item.keyword);
    }
  }, [editingKeyword]);

  const handleTextChange = ({ target: { value } }) => {
    // 👈  get the value
    if (value === '') {
      setBtnDisabled(true);
      setMessage(null);
    } else if (value.trim().length <= 2) {
      // 👈 check for less than 2
      setMessage('Text must be at least 3 characters');
      setBtnDisabled(true);
    } else {
      setMessage(null);
      setBtnDisabled(false);
    }
    setText(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (text.trim().length > 2) {
      const newKeyword = {
        keyword: text,
      };

      if (editingKeyword.edit === true) {
        console.log(editingKeyword);
        updateKeyword(editingKeyword.item.id, newKeyword);
      } else {
        addKeyword(newKeyword);
      }

      // NOTE: reset to default state after submission
      setBtnDisabled(true); // 👈  add this line to reset disabled
      setText('');
    }
  };

  // NOTE: pass selected to RatingSelect so we don't need local duplicate state
  return (
    <Card>
      <form onSubmit={handleSubmit} className="keyword-form">
        <h2>Add / Change keyword here:</h2>
        <div className="input-group">
          <input
            onChange={handleTextChange}
            type="text"
            placeholder="Please provide a keyword"
            value={text}
          />
          <Button type="submit" isDisabled={btnDisabled}>
            Send
          </Button>
        </div>

        {message && <div className="message">{message}</div>}
      </form>
    </Card>
  );
}

export default KeywordForm;
