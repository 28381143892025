import PropTypes from 'prop-types';

function InlineCard({ children, reverse }) {
  // return <div className={`card ${reverse && 'reverse'}`}>{children}</div>

  return <div className="inline-card">{children}</div>;
}

InlineCard.defaultProps = {
  reverse: false,
};

InlineCard.propTypes = {
  children: PropTypes.node.isRequired,
  reverse: PropTypes.bool,
};

export default InlineCard;
