import React from 'react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import InlineKeywordItem from './InlineKeywordItem';
import DealsContext from '../context/DealsContext';

function InlineKeywords() {
  const { keywords } = useContext(DealsContext);
  if (!keywords) {
    return null;
  } else {
    return (
      <div className="inline_keywords-container">
        {keywords.map((keyword) => (
          <InlineKeywordItem key={keyword.id} item={keyword} />
        ))}
      </div>
    );
  }
}

export default InlineKeywords;
