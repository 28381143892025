import React from 'react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import KeywordItem from './KeywordItem';
import DealsContext from '../context/DealsContext';

function Keywords() {
  const { keywords } = useContext(DealsContext);
  return (
    <div className="keywords-container">
      <h1>Keywords</h1>
      {keywords.map((keyword) => (
        <KeywordItem key={keyword.id} item={keyword} />
      ))}
    </div>
  );
}

export default Keywords;
