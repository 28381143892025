import React from 'react';
import { useContext, useEffect, useState } from 'react';
import DealsContext from '../context/DealsContext';

function DealWithHighlight({ title }) {
  const { keyDealMap, keywords } = useContext(DealsContext);

  useEffect(() => {
    prepareHighlightSpan();
  }, [keyDealMap]);

  const [newTitle, setNewTitle] = useState([]);

  const prepareHighlightSpan = () => {
    Object.keys(keyDealMap).forEach(function (key, index) {
      let matchPos = title.toLowerCase().indexOf(key.toLowerCase());
      if (matchPos >= 0) {
        const pre = title.substring(0, matchPos);
        const span = <span className="matched">{key}</span>;
        const post = title.substring(matchPos + key.length);

        setNewTitle([pre, span, post]);
      }
    });
  };

  if (newTitle.length == 3) {
    return <div className="deal-item">{newTitle}</div>;
  } else {
    return <div className="deal-item">{title}</div>;
  }
}

export default DealWithHighlight;
