import { FaTimes, FaEdit } from 'react-icons/fa';
import { useContext } from 'react';
import PropTypes from 'prop-types';
import InlineCard from './shared/InlineCard';
import DealsContext from '../context/DealsContext';

function InlineKeywordItem({ item }) {
  const { deleteKeyword, editKeyword } = useContext(DealsContext);

  return (
    <InlineCard>
      <span onClick={() => editKeyword(item)}>{item.keyword}</span>
      {/* <button onClick={() => editKeyword(item)} className="edit">
        <FaEdit color="blue" />
      </button> */}
      <button onClick={() => deleteKeyword(item.id)} className="close">
        <FaTimes color="purple" />
      </button>
    </InlineCard>
  );
}

InlineKeywordItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default InlineKeywordItem;
