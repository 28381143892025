import React from 'react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import DealWithHighlight from './DealWithHighlight';
import InlineKeywords from './InlineKeywords';
import KeywordForm from './KeywordForm';
import Card from './shared/Card';

function MatchedDeals(props) {
  const keyPrefix = props.filtered ? 'filtered_' : 'all_';
  return (
    <div className="deals-container">
      <KeywordForm />
      <InlineKeywords />
      <h1>Deals</h1>
      <Link to="/keywords">Go to keywords list</Link>
      <p></p>
      {props.filtered ? (
        <Link to="/all_deals">All Deals</Link>
      ) : (
        <Link to="/">Filtered Deals</Link>
      )}

      {props.deals
        ? props.deals.map((deal) => (
            <Card key={keyPrefix + deal.link}>
              <DealWithHighlight title={deal.title} />
              <a href={deal.link}>link</a>
              {/* <div
                  className="content"
                  dangerouslySetInnerHTML={{ __html: deal['content:encoded'] }}
                ></div> */}
            </Card>
          ))
        : null}
    </div>
  );
}

export default MatchedDeals;
