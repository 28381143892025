import { FaTimes, FaEdit } from 'react-icons/fa';
import { useContext } from 'react';
import PropTypes from 'prop-types';
import Card from './shared/Card';
import DealsContext from '../context/DealsContext';

function KeywordItem({ item }) {
  const { deleteKeyword, editKeyword } = useContext(DealsContext);

  return (
    <Card>
      <div className="text-display">{item.keyword}</div>
      <button onClick={() => editKeyword(item)} className="edit">
        <FaEdit color="purple" />
      </button>
      <button onClick={() => deleteKeyword(item.id)} className="close">
        <FaTimes color="purple" />
      </button>
    </Card>
  );
}

KeywordItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default KeywordItem;
