import { createContext, useState, useEffect } from 'react';

const DealsContext = createContext();
export default DealsContext;

export const DealsProvider = ({ children }) => {
  const [keywords, setKeywords] = useState([]);
  const [filteredDeals, setFilteredDeals] = useState([]);
  const [keyDealMap, setKeyDealMap] = useState({});
  const [allDeals, setAllDeals] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [editingKeyword, setEditingKeyword] = useState({
    keyword: {},
    edit: false,
  });

  // choose aws serverless API (json hosted on S3) because heroku
  // servers are ephemeral and will reset db.json that's served
  //  const server = 'https://xiefeng.herokuapp.com';
  const server =
    'https://7ez3ss75bf.execute-api.us-west-2.amazonaws.com/dev/api';

  useEffect(() => {
    fetchKeywords();
    fetchAllDeals();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    filterDeals();
  }, [keywords, allDeals]);

  const fetchAllDeals = async () => {
    //aws lambda retrieving deals (CORS error if access feeds URL directly from browser)
    const feed = await fetch(
      'https://qytafnpdl2heytgh7ahxcfrylm0olivw.lambda-url.us-west-2.on.aws/'
    );
    const jsonData = await feed.json();
    setAllDeals(jsonData.items);
  };

  const filterDeals = () => {
    //filter based on current keywords
    if (!allDeals || !keywords) {
      return;
    }
    // console.log('keywords: ', keywords);
    // console.log('deals ', allDeals);
    let newMap = { ...keyDealMap };
    const filtered = allDeals.filter((deal) => {
      let isMatch = keywords.reduce((acc, cur) => {
        let found =
          acc ||
          deal.title.toLowerCase().indexOf(cur.keyword.toLowerCase()) >= 0;
        if (found && !acc) {
          // first match
          console.log('title', deal.title);
          console.log('keyword', cur.keyword);
          const existingMatch = newMap[cur.keyword] || {};
          newMap = {
            ...newMap,
            [cur.keyword]: { ...existingMatch, [deal.title]: 1 },
          };
        }
        return found;
      }, false);

      return isMatch;
    });

    setKeyDealMap(newMap);
    console.log('newMap', newMap);
    setFilteredDeals(filtered);
  };

  const fetchKeywords = async () => {
    const response = await fetch(`${server}/deal_keywords`);
    const data = await response.json();
    console.log(data);

    setKeywords(data);
  };

  const addKeyword = async (newKeyword) => {
    const response = await fetch(`${server}/deal_keywords`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newKeyword),
    });

    const data = await response.json();

    setKeywords([data, ...keywords]);
  };

  const deleteKeyword = async (id) => {
    if (window.confirm('Are you sure you want to delete?')) {
      await fetch(`${server}/deal_keywords/${id}`, { method: 'DELETE' });

      setKeywords(keywords.filter((item) => item.id !== id));
    }

    //need to handle keyDealMap change
  };

  const updateKeyword = async (id, updItem) => {
    console.log(`${id} ${updItem}`);
    const response = await fetch(`${server}/deal_keywords/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updItem),
    });

    const data = await response.json();

    setKeywords(keywords.map((item) => (item.id === id ? data : item)));

    setEditingKeyword({
      keyword: {},
      edit: false,
    });

    //need to handle keyDealMap change
  };

  const editKeyword = (item) => {
    setEditingKeyword({
      item,
      edit: true,
    });
  };

  const makeKeywordEditible = (keyword) => {
    setEditingKeyword({
      keyword,
      edit: true,
    });
  };

  return (
    <DealsContext.Provider
      value={{
        keywords,
        allDeals,
        filteredDeals,
        isLoading,
        editingKeyword,
        keyDealMap,
        deleteKeyword,
        addKeyword,
        updateKeyword,
        makeKeywordEditible,
        editKeyword,
      }}
    >
      {children}
    </DealsContext.Provider>
  );
};
